import React from 'react';
import { Link, useHistory } from "react-router-dom";
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Pagination from '@mui/material/Pagination';
import LinearProgress from '@mui/material/LinearProgress';


import AppSettings from '../../../API/appSettings';
import { getSession } from '../../../services/Login';
import translate from '../../../services/Translation';
import Header from '../../../components/HeaderComponent';
import { fetchPost } from '../../../services/GettingData';
import ErrorDialog from '../../../components/ErrorDialog';
import MoleculeView from '../../../components/MoleculeView';
import ProgressControl from '../../../components/ProgressControl';
import FilterComponent from '../../../components/Sockets/FilterComponent';
import TimeoutError from '../../../schemas/Exception/TimeoutError';
import RefreshError from '../../../schemas/Exception/RefreshError';
import HelpIconWithHint from '../../../components/HelpIconWithHint';
import { saveSubSearchFilterSettings } from '../../../services/HandlingState';
import NotAuthorizedError from '../../../schemas/Exception/NotAuthorizedError';
import ExpiredAccountError from '../../../schemas/Exception/ExpiredAccountError';
import '../../../App.css';
import { Item, Item2, Found } from '../../../styles';
import WebSocketConnection from '../WebSocketConnection';

import Accept from '../schemas/Compound/Accept';
import SearchInstance from '../schemas/Compound/SearchInstance';
import { SearchStatus } from '../schemas/Compound/SearchStatus';
import IGetResponse from '../schemas/Compound/IGetResponse';
import IProgressInfo from '../schemas/Compound/IProgressInfo';
import ISubSearchFilter from '../../../schemas/Sockets/Compound/ISubSearchFilter';
import IFinishInfo from '../schemas/Compound/IFinishInfo';


export type SubSearchProps = { svgContent: string, moleculeString: string };

export const SubSearchSocketPage: React.FC<SubSearchProps> = (props) => {
  const activeSubscriptions = new Set<string>();


  const getSVG = (): string => {
    if ((props as any).location?.state?.svgContent)
      return (props as any).location?.state?.svgContent;
    return localStorage.getItem(AppSettings.LOCAL_STORAGE_SVG_KEY + AppSettings.MAIN) || '';
  }

  const getMolecule = (): string => {
    let molString = localStorage.getItem('mol_query');//A14
    if (molString) {
      console.log('from local storage', molString);
      return JSON.parse(molString);
    }
    if ((props as any).location?.state?.moleculeString)
      return (props as any).location?.state?.moleculeString;
    return localStorage.getItem(AppSettings.LOCAL_STORAGE_MOL_KEY + AppSettings.MAIN) || '';

  }

  const getExtraSVGs = (): string[] => {
    let svg = localStorage.getItem(AppSettings.LOCAL_STORAGE_SVG_KEY + AppSettings.EXTRA) || '';
    localStorage.removeItem(AppSettings.LOCAL_STORAGE_SVG_KEY + AppSettings.EXTRA);
    return svg ? [svg] : []
  }

  const getExtraMolecules = (): string[] => {
    let mol = localStorage.getItem(AppSettings.LOCAL_STORAGE_MOL_KEY + AppSettings.EXTRA) || '';
    localStorage.removeItem(AppSettings.LOCAL_STORAGE_MOL_KEY + AppSettings.EXTRA);
    return mol ? [mol] : []
  }

  const loadFilterSettings = (): ISubSearchFilter => {
    let filters = {
      structure: getMolecule(),
      compound_props: JSON.parse(localStorage.getItem('compound_props') || '[]'),
      show_radicals: false,
      iterate_tautomers: false,
      optional_structures: getExtraMolecules(),
      nmr_types: JSON.parse(localStorage.getItem('nmr_types') || '["H_NMR"]'),
      optional_svgs: getExtraSVGs(),
    }
    localStorage.removeItem('nmr_types');
    localStorage.removeItem('compound_props');
    localStorage.removeItem('mol_query');

    return filters;
  }

  //const [searchId, setSearchId] = React.useState<string | null>();
  //const [search, setSearch] = React.useState<SearchInstance>();
  const [search, setSearch] = React.useState<string>();
  const searchRef = React.useRef<string | undefined>(search);
  const [searchStatus, setSearchStatus] = React.useState<SearchStatus>();//TODO move to search


  const [isLoading, setIsLoading] = React.useState<boolean>(true);//A15
  const [svgContent] = React.useState<string>(getSVG());

  const [compoundSearchResponse, setCompoundSearchResponse] = React.useState<IGetResponse>();
  const [isErrorDialogOpened, setErrorDialogOpened] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [subSearchFilter, setSubSearchFilter] = React.useState<ISubSearchFilter>(loadFilterSettings());
  const [socketConnection, setSocketConnection] = React.useState<WebSocketConnection>();
  const [inProgressFound, setInProgressFound] = React.useState<number>(0);

  // ((props as any).location?.state?.subSearchFilterModel ? (props as any).location?.state?.subSearchFilterModel : loadFilterSettings());

  const [currentPage, setCurrentPage] = React.useState<number>((props as any).match.params['page']);

  let history = useHistory();

  const onAccept = (packet: Accept, connection: any) => {
    console.log('/search/accepted happens', packet.id);

    //socketConnection.getSocket().on('/search/progress', (packet) => onProgress(packet));
    connection.on('/search/finish', (packet) => onFinish(packet, packet.id));
    setSearch(packet.id);
      
    //setSearch(new SearchInstance(packet.id));
    //setSearch(packet.id);

  }

  React.useEffect(() => {
    searchRef.current = search;
  }, [search]);


  // React.useEffect(() => {
  //   const onProgress = (packet: any) => {
  //     console.log('Current search value:', searchRef.current);
  //     // Handle the progress packet using the latest search value
  //   };

  //   connection.on('/search/progress', onProgress);

  //   return () => {
  //     connection.off('/search/progress', onProgress);
  //   };
  // }, []);

  const onError = (packet: any) => {
    console.log('/error', packet.error);
    showError(packet.error);
    setIsLoading(false);
    //setSearch(undefined);
    //setSearchId(null);
  }

  const onFinish = (packet: IFinishInfo, searchId: string) => {
    if (packet.id === searchId) {
      console.log('/search/finish our obtained ');
      //search?.finish();
      setSearchStatus(SearchStatus.Finished);
      //get(currentPage);
    }
  }



  React.useEffect(() => {
    if (searchStatus === SearchStatus.Finished) {
      console.log('get got');
      get(currentPage);
    }
    if (searchStatus === SearchStatus.InProgress) {
      setCurrentPage(1);
      console.log('search status became in progress');
      get(1);
    }

  }, [searchStatus, currentPage]);


  const onProgress = (progress: IProgressInfo) => {
    //console.log('/search/progress', progress);
    //console.log('/search/progress searchId=', searchRef.current);
    if (progress.search_id === searchRef.current) {
      console.log('progress with this query', progress.progress.n_items);
      if (searchStatus !== SearchStatus.InProgress) {
        console.log('change status to in progress');
        setInProgressFound(progress.progress.n_items);
        setSearchStatus(SearchStatus.InProgress);
      }
    }
  }

  const startStructSearch = async (): Promise<boolean> => {
    console.log('run struct search with filter=', subSearchFilter);
    if (socketConnection) {
      console.log('run query', subSearchFilter);
      setSearchStatus(SearchStatus.Started);
      //connection.emit("/search/start",  {"type": "sub", "params": {"structure":"C1=CC(CC(NC(C2=CC=CC=C2)CN)([H])[H])=CC=C1"} });
      setCompoundSearchResponse(undefined);
      socketConnection.getSocket().emit("/search/start", { "type": "sub", "params": subSearchFilter });

    }
    //return { items: [], total: 0, page: 0, size: 0 }
    return true;

  }



  const onGet = (data: IGetResponse) => {
    console.log('obtained items', data.items);
    console.log('obtained data', data);
    setCompoundSearchResponse(data);
    setIsLoading(false);
  }


  const get = (currentPage: number) => {
    if (search && socketConnection) {
      const connection = socketConnection.getSocket();
      console.log('get search with a page', search, currentPage);
      connection.emit("/search/get", {
        "id": search,
        "page": currentPage, 
        'per_page': 11
      });
    }
  }

  React.useEffect(() => {
    const subscribeToEvent = (event: string, handler: (data: any) => void) => {
      if (!activeSubscriptions.has(event)) {
        socketConnection?.getSocket().on(event, handler);
        activeSubscriptions.add(event);
      }
    };

    if (socketConnection) {
      const connection = socketConnection.getSocket();
      //connection.on('/search/get', (data) => onGet(data));
      subscribeToEvent('/search/get', (packet) => onGet(packet));
      connection.on('/error', (data) => onError(data));
      //connection.on('/search/finish', (packet) => onFinish(packet));
      connection.on('/search/progress', (packet) => onProgress(packet));
      //subscribeToEvent('/search/progress', (packet) =>{ 
      //  onProgress(packet)});
      connection.on('/search/accepted', (packet) => onAccept(packet, connection));
      //subscribeToEvent('/search/accepted', (packet) => onAccept(packet));
      console.log('subscription has finished');

      return () => {
        connection.off('/search/progress', onProgress);
        connection.off('/search/accepted', onAccept);
        connection.off('/search/finish', onFinish);
      };
    }
  }, [socketConnection])


  React.useEffect(() => {
    setSocketConnection(new WebSocketConnection());
  }, [])


  // const endPoint = '/compound/search/sub?page=' + page + '&size=' + compoundSearchResponse?.size;
  // try {
  //   const response = await fetchPost(endPoint, JSON.stringify(subSearchFilterModel), true, true);
  //   if (!response.ok) {
  //     showError(response.statusText);
  //     return { items: [], total: 0, page: 0, size: 0 }
  //   }
  //   return await response.json();
  // }
  // catch (e: any) {
  //   setIsLoading(false);
  //   if (e instanceof NotAuthorizedError)
  //     (props as any).history.push({ pathname: '/login', state: { backTo: endPoint, welcomeMessage: true } })
  //   if (e instanceof ExpiredAccountError)
  //     (props as any).history.push({ pathname: '/personal', state: { welcomeMessage: true, expired: true } });
  //   if (e instanceof RefreshError) {
  //     showError(e.message);
  //     (props as any).history.push({ pathname: '/login', state: { backTo: endPoint, welcomeMessage: true } })
  //   }
  //   if (e instanceof TimeoutError) {
  //     console.error(e.message);
  //     showError(e.message);
  //   }
  //   else
  //     showError(e.toString());
  //   return { items: [], total: 0, page: 0, size: 0 }
  // }



  const refreshSub = async () => {
    //setCompoundSearchResponse(await searchAsSub(page));
    startStructSearch();
    //setIsLoading(false);
  }


  // const saveFilterSettings = () => {
  //   localStorage.setItem('compound_props', JSON.stringify(subSearchFilterModel?.compound_props));
  //   localStorage.setItem('nmr_types', JSON.stringify(subSearchFilterModel?.nmr_types));
  //   localStorage.setItem(AppSettings.LOCAL_STORAGE_MOL_KEY + AppSettings.EXTRA, subSearchFilterModel?.optional_mols[0] || '');
  // } A12


  React.useEffect(() => {
    console.log('useEffect current page');
    refreshSub();
  }, [socketConnection]);


  const pageChangeHandle = async (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page); //A14
    //history.push({
    //  pathname: '/sub-search-sockets/' + page,
    //  state: { subSearchFilterModel: subSearchFilter, page: page }
    //});
  }


  const showError = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    setErrorDialogOpened(true);
    setIsLoading(true);
  }


  const closeErrorDialog = () => {
    setErrorDialogOpened(false);
  }


  const newSearch = (): void => {
    localStorage.removeItem(AppSettings.LOCAL_STORAGE_MOL_KEY + AppSettings.MAIN);
    history.push('/editor/main');
  }


  const applyFilter = async (): Promise<void> => {
    setIsLoading(true);
    await refreshSub();
  }


  const filterChanged = (filterModel: ISubSearchFilter) => {
    setSubSearchFilter(prev =>
    ({
      ...filterModel,
      structure: (subSearchFilter?.structure as string),
      optional_structures: (subSearchFilter?.optional_structures as string[]),
      optional_svgs: (subSearchFilter?.optional_svgs as string[]),
    }
    ))
  }

  const obtainTotal = (compoundSearchResponse: IGetResponse | undefined): string => {
    if (searchStatus === SearchStatus.Started)
      return '';
    if (searchStatus === SearchStatus.InProgress && inProgressFound > 0)
      return 'Found: ' + inProgressFound + ' molecules';
    if (searchStatus === SearchStatus.Finished && compoundSearchResponse)
      return 'Found: ' + (compoundSearchResponse.total > 0 ? compoundSearchResponse.total : 0) + ' molecules';
    return ''
  }

  return (<Grid container spacing={0} className='main-frame'>
    <ProgressControl isLoading={isLoading} showTime={true} />
    <Grid xs={12}>
      <Item2><div style={{ height: '2em' }}></div></Item2>
      { }
    </Grid>
    <ErrorDialog isDialogOpened={isErrorDialogOpened}
      errorMessage={errorMessage}
      onClose={closeErrorDialog} />

    <Header title='Substructure search' showLogin={getSession() !== undefined} helpAddress='help#wdaafpc' />

    <Grid container spacing={4} style={{ minWidth: "250px", marginTop: '4em' }} md={12}>
      <Grid>
      </Grid>
      <Grid style={{ marginLeft: '1em', marginRight: '1em', display: "flex", alignItems: "center" }}>
        <div></div>
        <Item2>
          <div style={{ marginBottom: '1em' }}>
            <Tooltip arrow title='start new search'>
              <Button onClick={() => newSearch()} variant="contained">New Search</Button>
            </Tooltip>
            <HelpIconWithHint title={''}
              text={'Drop results and draw new query'}
              handleOpen={() => { }} />
          </div>
          <Link to='/editor/compound-search-sockets'>
            <Tooltip arrow title='modify query'>
              <Button variant="contained">Edit Search</Button>
            </Tooltip>
          </Link>
          <HelpIconWithHint title={''}
            text={'Drop results and modify the query'}
            handleOpen={() => { }} />
        </Item2>
      </Grid>
      <Grid style={{ minWidth: "250px", marginLeft: '1em', marginRight: '1em' }}>
        <div><span className='box-title'>Substructure search</span></div>
        <Item style={{ width: "250px", marginTop: '20px' }}>
          <MoleculeView link={''}
            svgContent={svgContent}
            isMoleculeInContainer={true}
            moleculeString={(props as any).location?.state?.moleculeString} />
        </Item>
      </Grid>

      <Grid style={{ minWidth: "250px", marginLeft: '1em', marginRight: '1em', }}>
        <div>Extra filter</div>
        <Item style={{ width: "250px", marginTop: '12px' }}
        //onClick={() => { saveSubSearchFilterSettings(subSearchFilter) }} //A14
        >
          {<MoleculeView link={'/editor/extra'}
            svgContent={subSearchFilter.optional_svgs[0] || ''}
            tooltip={'Open ketcher to draw substructure to filter results'}
            isMoleculeInContainer={true} />}
        </Item>
      </Grid>

      <Grid>
        <div style={{ marginBottom: '20px' }}><span className='box-title'>Filter</span></div>
        {<FilterComponent
          onChanged={filterChanged}
          subSearchFilter={subSearchFilter} />}
        <div style={{ marginBottom: '1em', marginTop: '2em', textAlign: 'center' }}>
          <Tooltip arrow title='Apply selected filters to your results'>
            <Button onClick={applyFilter} variant="contained">Apply Filter</Button>
          </Tooltip>
          <HelpIconWithHint title={''}
            text={'Apply selected filters to the current search results'}
            handleOpen={() => { }} />
        </div>
      </Grid>

      <Grid style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginBottom: '1em' }}>
        </div>
      </Grid>
    </Grid>

    <Grid md={12} xs={12}>
      {!isLoading &&
        <Grid md={12} xs={12} style={Found}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              {obtainTotal(compoundSearchResponse)}
            </div>
            {searchStatus === SearchStatus.InProgress && (
              <div style={{ marginLeft: '1em', flexGrow: 1, maxWidth: '3em' }}>
                <LinearProgress />
              </div>
            )}
          </div>
        </Grid>
      }
    </Grid>

    {compoundSearchResponse?.items &&
      compoundSearchResponse.items.map((compound, index) => (
        <Grid container sm={12} md={10} style={{ paddingTop: '0.7em' }} key={index} className='compound-feed'>
          <Grid sm={12} md={3} >
            <MoleculeView link={''} moleculeId={compound.id}
              svgContent={compound.svg}
              isMoleculeInContainer={true}
              inline={true}
              moleculeString={compound.molecule}
            />
          </Grid>
          <Grid container sm={12} md={9}>
            <Grid sm={4} xs={12}>
              {compound.total_publications ? <div>Publications found: {compound.total_publications}</div> : null}
            </Grid>
            <Grid sm={4} xs={12}>
              {
                Object.entries(compound.properties).map(([key, value]) => {
                  return (value > 0 && <div key={key}>{translate(key)} found: {value}</div>)
                })
              }
            </Grid>
            <Grid sm={4} xs={12}>
              {compound.associated_spectra && Object.entries(compound.associated_spectra).map((spectra, count) => (
                <div key={spectra[0]}>{translate(spectra[0])} spectra found: {spectra[1]}</div>
              ))}
            </Grid>
          </Grid>
        </Grid>
      ))

    }

    <Grid md={12} xs={12} className='pagination-line' style={{ display: "inline" }} >
      {!isLoading && (searchStatus === SearchStatus.Finished || searchStatus === SearchStatus.InProgress) &&
        <Grid style={{ width: '100%' }}>
          {compoundSearchResponse && compoundSearchResponse?.total > 0 &&
            <Pagination count={Math.ceil(compoundSearchResponse?.total / compoundSearchResponse?.pagination.per_page)}
              page={compoundSearchResponse?.pagination.page} onChange={pageChangeHandle} />}
        </Grid>
      }
    </Grid>
  </Grid>);
}
export default SubSearchSocketPage;
