import React, { useEffect, useState } from 'react';
import WebSocketConnection from './WebSocketConnection'; // Adjust the import path as necessary

const F: React.FC = () => {
  const [messages, setMessages] = useState<string[]>([]);

  useEffect(() => {
    const webSocketConnection = new WebSocketConnection();

    // Define a function to handle incoming messages
    const handleMessage = (data: any) => {
      setMessages((prevMessages) => [...prevMessages, JSON.stringify(data)]);
    };

    // Register event listeners for message and response events
    //if (webSocketConnection.onMessage) {
    //  webSocketConnection.onMessage(handleMessage);
    //}
    //webSocketConnection.onResponse(handleMessage);

    // Cleanup function to remove event listeners
    return () => {
      //webSocketConnection.removeEventListener("message", handleMessage);
      //webSocketConnection.removeEventListener("response", handleMessage);
    };
  }, []);

  return (
    <div>
      <h1>WebSocket Messages</h1>
      <ul>
        {messages.map((message, index) => (
          <li key={index}>{message}</li>
        ))}
      </ul>
    </div>
  );
};

export default F;